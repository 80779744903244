<template>
  <vuestic-widget v-if="user" :loading="loading">
    <template slot="header">
      <span class="title">SMS Registration</span>
    </template>
    <div class="verification-page">
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="agencyForm">
        <form @submit.prevent="handleSubmit(submit)">
          <div class="row mt-3">
            <div class="col-md-12">
              <p class="font-weight-bold"><span class="text-primary">Starter:</span> If your business sends fewer than
                2000
                text messages per day and uses 2 or fewer phone numbers</p>
              <p class="font-weight-bold"><span class="text-primary">Standard:</span> If your business sends more than
                2000
                text messages per day or uses 3 or more phone numbers, you should register your business as a "Standard"
                brand. There is $10/month campaign fee.</p>
            </div>
            <div v-if="campaign.status" class="col-md-12 my-3">
              <div class="alert alert-primary d-block" :class="{'alert-danger': campaign.status === 'failed'}">
                <span class="d-block text-primary font-weight-bold">Status: {{ campaign.statusStr }} <i
                    v-if="isCampaignProcessing(campaign.status)" class="fa fa-refresh fa-spin"></i></span>
                <br/>
                <span v-if="isCampaignProcessing(campaign.status)" class="d-block font-weight-bold">This registration is under review, and may take several weeks to complete. You may check back on
                  this registration status in a couple of days.</span>
                <span v-if="campaign.status === 'failed'">
                  SMS Registration is failed due to unexpected reason. Please contact admin at <a :href="`mailto:${agencyEmail}`" target="_blank" class="font-weight-bold text-decoration-underline">{{ agencyEmail }}</a> for more information!
                </span>
              </div>
            </div>
            <div v-if="!twBrandRegistered" class="col-md-12 my-3">
              <div class="alert alert-primary d-block">
                You are not allowed to submit <b>SMS registration</b> at the moment, Please contact admin at <a :href="`mailto:${agencyEmail}`" target="_blank" class="font-weight-bold text-decoration-underline">{{ agencyEmail }}</a> for more information!
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <span class="lelabel">Business Type</span>
              <div class="d-flex mt-2">
                <div class="radio abc-radio abc-radio-primary">
                  <input type="radio" name="campaignType" id="campaignTypeStarter" value="starter"
                    v-model="campaign.campaignType" />
                  <label for="campaignTypeStarter">
                    <span class="abc-label-text">Starter</span>
                  </label>
                </div>
                <div class="radio abc-radio abc-radio-primary ml-5">
                  <input type="radio" name="campaignType" id="campaignTypeStandard" value="standard"
                    v-model="campaign.campaignType" />
                  <label for="campaignTypeStandard">
                    <span class="abc-label-text">Standard</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <text-input name="Website" label="Website" v-model="campaign.website"
                help="Website that you are going to add widget/form to. Leave this blank if you do not use widget/form." />
            </div>
            <div class="col-md-6"></div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button v-if="campaign.status" type="button" class="btn btn-primary" :disabled="processing"
                @click="onRefresh">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Refresh</span>
              </button>
              <button v-if="!campaign.status" class="btn btn-primary" :disabled="processing || !twBrandRegistered">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>Submit</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </vuestic-widget>
</template>
  
<script>
const { getCodeList } = require('country-list');
export default {
  data() {
    return {
      loading: false,
      processing: false,
      campaign: {
      }
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    onRefresh() {
      this.loadData()
    },
    isCampaignProcessing(status) {
      return ['pending', 'in_progress'].includes(status)
    },
    loadData() {
      this.loading = true
      this.$store
        .dispatch('twilio/getCampaign')
        .then((res) => {
          this.loading = false
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.campaign = res.data
            if (!this.campaign.campaignType) {
              this.campaign.campaignType = 'starter'
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    submit() {
      this.processing = true
      this.$store
        .dispatch('twilio/registerCampaign', this.campaign)
        .then((res) => {
          this.processing = false
          if (!res.success) {
            this.errors = res.data
            Vue.$toast.open({
              message: res.message,
              type: 'error',
            })
          } else {
            this.onRefresh();
          }
        })
        .catch((err) => {
          console.log(err)
          this.processing = false
        })
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    business() {
      return this.user && this.user.business
    },
    agency() {
      return this.user && this.user.agency
    },
    agencyEmail () {
      return this.agency && this.agency.email || '';
    },
    twBrandRegistered() {
      return this.agency && this.agency.tw_brand_sid
    }
  }
}
</script>
  
<style scoped lang="scss">.verification-page {
  max-width: 1024px;
}</style>